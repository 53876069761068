import React from 'react'
import Img from 'gatsby-image'
import {buildImageObj} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'
import clientConfig from '../../../client-config'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import styles from './gallery.module.css'

function Gallery (props) {
  console.log({props})
  // return null
  const fluidProps = (slide) => {
    if(!slide?.asset?._ref) {
      return null
    }
    return getFluidGatsbyImage(slide?.asset?._ref, {maxWidth: 640}, clientConfig.sanity)
  }
  return (
    <div className={styles.galleryWrapper}>

      {props?.images?.map(slide => (<div key={slide._key} className={styles.galleryItem}>
        <Img fixed={fluidProps(slide)} alt={slide.title}  /></div>
      ))}

    </div>
  )
}
export default Gallery
