import React from 'react'

export default (props) => {
  let linkText = props.children[0]
  let linkID = props.mark.file.asset._ref
  let linkURL = linkID.split('-')[1]
  let linkExt = linkID.split('-')[2]
  linkURL = `https://cdn.sanity.io/files/kzlvqwn9/production/${linkURL}.${linkExt}`
  return (
    <><a href={linkURL} target='_blank' rel='noopener noreferrer'>{linkText}</a></>
  )
}
