import {Link, graphql, StaticQuery} from 'gatsby'
import React from 'react'
// import Image from 'gatsby-image'
import Navigation from './Navigation'
// import Icon from './icon'
import Logo from '../images/dda-logo--white.svg'
import Welcome from '../images/welcome-to-downtown.svg'
import Separator from '../images/separator.svg'

import {cn} from '../lib/helpers'

import styles from './header.module.css'

const Header = ({location, onHideNav, onShowNav, showNav, mainImage}) => {
  // console.log({mainImage})
  return (
    <>
      <div className={location === '/' ? styles.rootHome : styles.root}>
        <div className={styles.wrapper} style={{alignItems: `center`}}>
          <div className={styles.innerWrapper}>

            <div className={styles.branding}>
              <Link to='/' aria-label='Logo'>
                <span className={styles.logoWrapper}><Logo /></span>
                <span className={styles.separatorWrapper}><Separator /></span>
                <span className={styles.welcomeWrapper}><Welcome /></span>
              </Link>
            </div>

            {/* <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav} aria-label='Mobile Naviagation'>
              <Icon symbol='hamburger' />
            </button> */}

            <button className={showNav ? styles.menuToggleOn : styles.menuToggle} onClick={showNav ? onHideNav : onShowNav} aria-label='Mobile Menu' />

            <StaticQuery
              query={graphql`
            query sanityNav {
              sanityNavigation(_id: { eq: "b1db3bbe-0928-4a73-8425-16b395ba37b2" }) {
                links {
                  _key
                  title
                  siteLink
                  links {
                    _key
                    title
                    siteLink
                  }
                }
              }
            }
          `}
              render={data => (
                <nav className={cn(styles.nav, showNav && styles.showNav)}>
                  <Navigation nav={data.sanityNavigation} main />
                </nav>
              )}
            />

          </div>
        </div>
      </div>
      {/* {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <Image fluid={mainImage.asset.fluid} alt='hero' />
        </div>
      )} */}
    </>
  )
}

export default Header
