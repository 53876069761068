import React from 'react'
import Img from 'gatsby-image'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../../client-config'

// import {buildImageObj} from '../../lib/helpers'
// import {imageUrlFor} from '../../lib/image-url'

// https://www.npmjs.com/package/react-responsive-carousel
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import './slideshow.module.css'

function Slideshow (props) {
  // console.log({props})

  const fluidProps = (slide) => getFluidGatsbyImage(slide.slideImage.asset._ref, {maxWidth: 640}, clientConfig.sanity)
  return (
    <div style={{maxWidth: '700px', margin: '0 auto'}}>
      <Carousel showStatus dynamicHeight swipeable showThumbs={false}>
        {props.slides.map(slide => (
          <Img key={props._key} fluid={fluidProps(slide)} alt={slide.title} />
        ))}
      </Carousel>
    </div>
  )
}
export default Slideshow
