import React from 'react'
import Figure from './Figure'
import PDFfile from './PDFfile'
import Youtube from './Youtube'
import Slideshow from './Slideshow'
import Gallery from './Gallery'
import Iframe from './Iframe'
import FileDownload from './FileDownload'

const serializers = {

  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    youtube (props) {
      return <Youtube {...props.node} />
    },
    slideshow (props) {
      return <Slideshow {...props.node} />
    },
    imageGallery (props) {
      return <Gallery {...props.node} />
    },
    pdfFile (props) {
      return <PDFfile {...props.node} />
    },
    iframe (props) {
      return <Iframe {...props.node} />
    }
  },

  marks: {
    fileDownload (props) {
      // console.log({props})
      switch (props.mark._type) {
        case 'fileDownload':
          if (props.mark.file) { return <FileDownload {...props} /> } else { return null }
      }
    },
    link: ({mark, children}) => {
      // Read https://css-tricks.com/use-target_blank/
      const {blank, href} = mark
      return blank
        ? <a href={href} target='_blank' rel='noopener noreferrer'>{children}</a>
        : <a href={href}>{children}</a>
    }
  },
  list: props => {
    // console.log({props})
    switch (props.type) {
      case 'roman': {
        return <ol className='tableList'>{props.children}</ol>
      }
      case 'number': {
        return <ol>{props.children}</ol>
      }
      case 'bullet': {
        return <ul>{props.children}</ul>
      }
      default: {
        return <ul>{props.children}</ul>
      }
    }
  }
}

export default serializers
