import React from 'react'
import {Location} from '@reach/router'
import Header from './header'

import styles from './layout.module.css'

import '../styles/layout.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, mainImage, title}) => (
  <>
    <a className={styles.skipLink} href='#main'>Skip to main</a>
    {/* <div className={location.pathname === '/' ? styles.contentHome : styles.content}> */}
    <Location children={children}>
      {({location}) => {
        // console.log({location})
        // console.log({mainImage})
        // console.log({siteTitle})
        return (
          <div
            className={
              location.pathname.replace(/\//g, '') === ''
                ? `${styles.contentHome}`
                : location.pathname.replace(/\//g, '')
            }
          >
            <Header
              location={location.pathname}
              siteTitle={siteTitle}
              onHideNav={onHideNav}
              onShowNav={onShowNav}
              showNav={showNav}
              mainImage={mainImage}
            />
            <a id='main' />
            <div className={styles.root}>{[children]}</div>
          </div>
        )
      }}
    </Location>

    {/* </div> */}
  </>
)

export default Layout
