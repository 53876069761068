import React from 'react'

import './figure.module.css'

export default (props) => {
  console.log({props})
  let PDFpath = props.asset._ref
  PDFpath = PDFpath.replace('file-', '')
  PDFpath = PDFpath.replace('-pdf', '')
  PDFpath = `https://cdn.sanity.io/files/kzlvqwn9/production/${PDFpath}.pdf`
  return (
    <>
      <object data={PDFpath} type='application/pdf' width='100%' height='960px' internalinstanceid='11'>
        {props.alt ? (<>{props.alt}</>) : (<>Your browser can not view this PDF Embed, however you can click the link below to download.</>)}
      </object>
      <a href={PDFpath}>Download</a>
    </>
  )
}
